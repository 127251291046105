/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl.eot');
    src: url('../fonts/MuseoSansCyrl.eot') format('embedded-opentype'),
         url('../fonts/MuseoSansCyrl.woff2') format('woff2'),
         url('../fonts/MuseoSansCyrl.woff') format('woff'),
         url('../fonts/MuseoSansCyrl.ttf') format('truetype'),
         url('../fonts/MuseoSansCyrl.svg#MuseoSansCyrl') format('svg');
}
@font-face {
    font-family: 'MuseoSansCyrl_0';
    src: url('../fonts/MuseoSansCyrl_0.eot');
    src: url('../fonts/MuseoSansCyrl_0.eot') format('embedded-opentype'),
         url('../fonts/MuseoSansCyrl_0.woff2') format('woff2'),
         url('../fonts/MuseoSansCyrl_0.woff') format('woff'),
         url('../fonts/MuseoSansCyrl_0.ttf') format('truetype'),
         url('../fonts/MuseoSansCyrl_0.svg#MuseoSansCyrl_0') format('svg');
}
@font-face {
    font-family: 'MuseoSansCyrl_1';
    src: url('../fonts/MuseoSansCyrl_1.eot');
    src: url('../fonts/MuseoSansCyrl_1.eot') format('embedded-opentype'),
         url('../fonts/MuseoSansCyrl_1.woff2') format('woff2'),
         url('../fonts/MuseoSansCyrl_1.woff') format('woff'),
         url('../fonts/MuseoSansCyrl_1.ttf') format('truetype'),
         url('../fonts/MuseoSansCyrl_1.svg#MuseoSansCyrl_1') format('svg');
}
@font-face {
    font-family: 'MuseoSansCyrl_2';
    src: url('../fonts/MuseoSansCyrl_2.eot');
    src: url('../fonts/MuseoSansCyrl_2.eot') format('embedded-opentype'),
         url('../fonts/MuseoSansCyrl_2.woff2') format('woff2'),
         url('../fonts/MuseoSansCyrl_2.woff') format('woff'),
         url('../fonts/MuseoSansCyrl_2.ttf') format('truetype'),
         url('../fonts/MuseoSansCyrl_2.svg#MuseoSansCyrl_2') format('svg');
}
@font-face {
    font-family: 'MuseoSansCyrl_3';
    src: url('../fonts/MuseoSansCyrl_3.eot');
    src: url('../fonts/MuseoSansCyrl_3.eot') format('embedded-opentype'),
         url('../fonts/MuseoSansCyrl_3.woff2') format('woff2'),
         url('../fonts/MuseoSansCyrl_3.woff') format('woff'),
         url('../fonts/MuseoSansCyrl_3.ttf') format('truetype'),
         url('../fonts/MuseoSansCyrl_3.svg#MuseoSansCyrl_3') format('svg');
}


$font1: 'MuseoSansCyrl';
$font2: 'MuseoSansCyrl_0';
$font3: 'MuseoSansCyrl_1';
$font4: 'MuseoSansCyrl_2';
$font5: 'MuseoSansCyrl_3';
@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $font3;
	color: #58595b;
	margin: 0;
}

h1 {
	font-family: $font3;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #58595b;
	line-height: 1;
	margin-bottom: 25px;
	@media screen and (max-width: 991px) {
		font-size: 32px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-family: $font3;
	font-size: 36px;
	color: #58595b;
	margin: 0 0px 20px;	
	a {
		font-family: $font3;
		font-size: 36px!important;
		color: #282727;
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $font5;
	font-size: 24px;
	color: #58595b;
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
	@media screen and (max-width: 767px) {
		margin-top: 0;
	}
}
h4 {
	font-family: $font4;
	font-size: 22px;
	color: #58595b;
}
h5 {
	font-family: $font4;
	font-size: 18px;
	color: #58595b;
}
h6 {
	font-family: $font4;
	font-size: 14px;
	text-transform: uppercase;
	color: #58595b;
}
a {
	color: #58595b;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #58595b;
		text-decoration: none;
	}
	&:hover {
		color: #58595b;
		text-decoration: underline;
	}
}
input, textarea, button, select {
	border-radius: 0px;
	-webkit-appearance: none;
}
.row {
	margin: 0 -30px;
	@media screen and (max-width: 1610px) {
		margin: 0 -15px;
	}
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
	padding: 0px 30px;
	@media screen and (max-width: 1610px) {
		padding: 0 15px;
	}
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 17px;
	background-color: #fff;
	textarea, input {
		background-color: #fff;
		font-family: $font2;
		font-size: 16px;
		padding: 5px 15px;
	}
}
.main {
	margin-top: 100vh;
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	a:hover {
		text-decoration: none;
	}
	@media screen and (max-width: 1024px) {
		margin-top: 0;
	}
}
.container {
	padding: 0 30px;
	@media screen and (min-width: 1610px) {
		width: 1610px;
	}
	@media screen and (max-width: 991px) {
		padding: 0 15px;
	}

}
.btn {
	display: table;
	height: 42px;
	width: auto!important;
	background-color: transparent!important;
	font-family: $font4!important;
	font-size: 15px;
	color: #58595b;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #58595b;
	padding: 12px 20px 13px!important;
	text-decoration: none!important;
	transition: 400ms;
	line-height: 1;
	&:focus, &:active {
		background-color: transparent!important;
		color: #58595b;
	}
	&:hover, &.active {
		background-color: #58595b!important;
		color: #fff!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 18px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}
.more {
	display: table;
	position: relative;
	line-height: 1;
	&:after {
		content: '\f105';
		width: 18px;
		height: 18px;
		font-family: 'fontAwesome';
		font-size: 14px;
		color: #58595b;
		position: absolute;
		right: -25px;
		top: 0px;
		border: 1px solid #58595b;
		border-radius: 100%;
		padding: 1px 0 0 1px;
		text-align: center;
	}
	&.more--in {
		padding: 1px 0px 0px 25px;
		&:after {
			left: 0;
			right: auto;
			transition: 300ms;
		}
		&:hover {
			&:after {
				left: 5px;
				transform: scale(0.96);
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-right: 30px;
		&:after {
			right: 5px;
		}
	}
}

/* header */
header {
	min-height: 75px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border-bottom: 1px solid #c0cfd6;
	text-align: center;
	z-index: 9;
	&:after {
		content: '';
		height: 75px;
		background-color: #fff;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		opacity: 0;
		transition: 600ms;
		z-index: -1;
	}
	ul {
		@include ul-default;
	}

	/* header logo */
	.logo {
		position: relative;
		float: left;
		margin-top: 23px;
		@include inline-block;
		img {
			max-width: 100%;
			transition: 300ms;
		}
		.logo--hide {
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
	/* header logo */

	/* header nav */
	.nav {
		text-align: left;
		line-height: 1;
		@include inline-block;
		> ul > li {
			margin: 0 18px;
			@include inline-block;
			&.nav__langs {
				display: none;
			}
			> a {
				display: block;
				font-family: $font3;
				font-size: 16px;
				color: #fff;
				padding: 29px 0px;
				box-sizing: border-box;
				border-bottom: 2px solid transparent;
				margin-bottom: -1px;
			}
			&:hover {
				> a {
					text-decoration: none;
					border-bottom: 2px solid #000;
				}
			}
			&.active > a {
				font-family: $font4;
			}
			h5 {
				display: table;
				font-size: 17px;
				line-height: 1.5em;
				position: relative;
				.more {
					position: absolute;
					right: 0;
					top: 2px;
				}
			}
			.submenu__block {
				display: none;
				position: absolute;
				left: 0;
				top: 76px;
				right: 0;
				background-color: #fff;
				padding: 50px 0px;
				font-size: 16px;
				z-index: 1;
				> .container {
					opacity: 0;
				}
				.submenu__title {
					font-family: $font4;
					font-size: 24px;
					line-height: 1;
					margin-bottom: 40px;
					&:after {
						top: 5px;
					}
					&:hover {
						text-decoration: none; 
					}
				}
				.submenu__sub {
					width: 33.33%;
					float: left;
					font-size: 16px;
					padding-right: 35px;
					@include inline-block;
					li {
						padding: 15px 0;
						&.active {
							a {
								opacity: 0.7;
							}
						}
						a {
							display: block;
							&:hover {
								text-decoration: none;
								h5 {
									text-decoration: underline;
								}
								span {
									text-decoration: none;
								}
							}
						}
					}
				}
				.left {
					h5 {
						margin: 0;
					}
				}
				.right {
					h5 {
						display: table;
						background-color: #fff;
						padding-right: 10px;	
						margin-top: 0;
						&:after {
							content: '';
							height: 1px;
							background-color: #cccac8;
							position: absolute;
							top: 13px;
							right: 0;
							left: 30px;
							right: 30px;
							z-index: -1;
						}
					}
					ul li {
						margin: 10px 0;
						&.active a {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		float: right;
		position: relative;
		text-align: left;
		cursor: pointer;
		margin-top: 23px;
		line-height: 1;
		@include inline-block;
		.langs__select {
			display: block;
			padding: 7px 25px 5px 15px;
			color: #fff;
			position: relative;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			transition: 400ms;
			&:after {
				content: '\f107';
				font-family: 'fontAwesome';
				font-size: 16px;
				color: #fff;
				position: absolute;
				right: 8px;
				top: 4px;
				transition: 400ms;
			}
		}
		ul {
			display: none;
			min-width: 150px;
			position: absolute;
			right: 0;
			top: 27px;
			padding: 10px 15px;
			background-color: #fff;
			border-left: 1px solid #c8c8c8;
			border-right: 1px solid #c8c8c8;
			border-bottom: 1px solid #c8c8c8;
			box-sizing: border;
			z-index: 1;
			&:after {
				content: '';
				height: 1px;
				background-color: #c8c8c8;
				position: absolute;
				top: 0;
				right: 59.33px;
				left: 0;
			}
			li {
				a {
					display: block;
					white-space: nowrap;
					padding: 10px 0px;
					font-size: 12px;
					text-transform: uppercase;
				}
				&.active a {
					font-family: $font4;
				}
			}
		}
		&.open .langs__select {
			background-color: #fff;
			color: #cccac8;
			border-top: 1px solid #c8c8c8;
			border-right: 1px solid #c8c8c8;
			border-left: 1px solid #c8c8c8;
			&:after {
				color: #cccac8;
			}
		}
	}
	/* header langs */
	@media screen and (max-height: 1610px) {
		.nav > ul > li {
			margin: 0 6px;
		}
	}
	@media screen and (max-width: 1199px) {
		min-height: 50px;	
		position: fixed;
		background-color: #fff;
		&:after {
			height: 100%;
		}
		&.scroll {
			&:after {
				opacity: 1;
			}
			.logo {
				.logo--hide {
					opacity: 1;
				}
				.logo--vis {
					opacity: 0;
				}
			}
			.nav--btn span {
				background-color: #58595b;
			}
		}
		&.header--hover {
			.nav--btn span {
				background-color: #58595b;
			}
		}
		> .container {
			position: relative;
			padding: 0 15px;
			> .langs {
				display: none;
			}
		}
		.logo {
			display: table;
			float: none;
			margin-top: 12px;
			.logo--hide {
				opacity: 1;
			}
			.logo--vis {
				opacity: 0;
			}
		}
		.nav {
			display: none;
			height: calc(100% - 50px);
			width: 100%;
			position: fixed;
			top: 50px;
			left: 0;
			right: 0;
			padding: 0px;
			background-color: #fff;
			margin: 0;
			overflow: auto;
			z-index: 9;
			> ul {
				width: 970px;
				padding: 0 15px;
				margin: 0 auto;
				> li {
					display: block;
					margin: 0;
					border-bottom: 1px solid #cccac8;
					&.nav__langs {
						display: block;
					}
					.submenu__block {
						width: 100%;
						position: relative;
						top: 0;
						left: 0;
						padding: 0;
						> .container {
							padding: 0;
							opacity: 1;
						}
						.submenu__title {
							font-family: $font3;
							font-size: 16px;
							padding: 0 0 15px 0;
							margin: 0;
							&:after {
								display: none;
							}
						}
						.submenu__sub {
							display: block;
							width: 100%;
							li {
								padding: 0;
								a {
									display: block;
									padding-bottom: 12px;
								}
								h5 {
									font-family: $font3;
									font-size: 16px;
									margin: 0;
								}
								.more {
									display: none;
								}
							}
						}
						.left {
							display: none;
						}
						.right {
							> h5 {
								display: none;
							}
							.col-md-6 {
								display: block;
								width: 100%;
								ul li {
									margin: 0;
									a {
										display: block;
										padding-bottom: 12px;
										font-size: 16px;
									}
								}
							}
						}
					}
					> a {
						padding: 15px 20px 15px 0; 
						color: #585959;
						border: none!important;
					}
					&.submenu {
						position: relative;
						&:after {
							content: '\f107';
							font-family: 'fontAwesome';
							font-size: 25px;
							position: absolute;
							right: 0;
							top: 10px;
							line-height: 25px;
						}
					}
					&.nav__langs {
						display: block;
						border: none;
						.langs {
							position: relative;
							left: 0;
							top: 0;
							float: none;
							padding: 10px 0;
							margin: 0;
							.langs__select {
								display: table;
								padding: 8px 25px 8px 0;
								text-transform: uppercase;
								&:after {
									top: 6px;
								}
								&.active {
									opacity: 0.7;
									&:after {
										top: 6px;
										transform: rotate(-180deg);
									}
								}
							}
							ul {
								position: relative;
								top: 0;
								left: 0;
								border: none;
								padding: 0;
								&:after {
									display: none;
								}
								li a {
									padding: 8px 0;
									font-size: 14px;
								}
							}
						}
					}
					&.active {
						&:after {
							transform: rotate(-180deg);	
						}
						 > a {
							opacity: 0.7;
						}
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			right: 15px;
			top: 9px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #58595b;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.nav > ul {
			width: 750px;
		}
	}
	@media screen and (max-width: 767px) {
		.nav > ul {
			width: 100%;
		}
	}
}
.header--hover, 
.body header {
	color: #58595b;
	&:after {
		opacity: 1;
	}
	.nav > ul > li > a {
		color: #58595b;
	}
	.logo {
		.logo--vis {
			opacity: 0;
		}
		.logo--hide {
			opacity: 1;
		}
	}
	.langs {
		.langs__select {
			color: #58595b;
			&:after {
				color: #58595b;
			}
		}
	}
	.nav--btn span {
		background-color: #58595b;
	}
}
/* header */
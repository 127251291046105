/* page content */
.page {
	padding: 75px 0px 100px 0px;
	.row {
		margin: 0 -50px;
	}
	.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
		padding: 0px 50px;
	}
	.col-sm-6 img {
		display: block;
		width: 100%;
	}
	.news1, .news {
		.date {
			color: #7accc8;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #7accc8;
				text-decoration: none;
			}
		}
		h2 a {
			font-size: 24px!important;
		}
	}
	.page_nav {
		.active {
			color: #3361eb;
		}
	}
	a {
		color: #58595b;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #7accc8;
			text-decoration: none;
		}
	}
	.col-md-10 {
		padding: 75px 30px;
	}
	.banner {
		.banner__block .banner__block__title {
			animation-delay: 0s;
			animation-duration: 0s;
		}
	}
	.col-xs-6 img {
		max-width: 100%;
	}
	#photos-list td img {
		max-width: 100%;
		height: auto;
	}
	@media screen and (max-width: 1440px) {
		p img {
			max-width: 100%!important;
			height: auto!important;
		}
	}
	@media screen and (max-width: 1199px) {
		padding-top: 50px;
		.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
			padding: 0px 15px;
		}
		.row {
			margin: 0 -15px;
		}
		.col-md-10 {
			padding: 25px 15px;
		}
		.col-xs-6 {
			img {
				max-width: 100%;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 991px) {
		padding-bottom: 25px;
	}
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #7accc8;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #58595d;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

.with--banner {
	.banner {
		position: relative;
		margin-top: -75px;
		margin-bottom: 25px;
		.banner__img {
			position: relative;
			z-index: 0;
		}
		.banner__block {
			top: auto;
			bottom: 50px;
			.banner__block__title {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.banner {
			margin-top: -50px;
		}
	}
}

.with--bottom {
	padding-bottom: 100px;
	@media screen and (max-width: 1199px) {
		padding-bottom: 50px;
	}
}

/* page catalog */

.filter {
	font-family: $font2;
	font-size: 14px;
	.row {
		margin-top: 100px;
	}
	.filter__title {
		display: block;
		font-family: $font3;
		font-size: 15px;
		text-transform: uppercase;
		color: #7accc8;
		line-height: 1;
		padding: 20px 0px;
		border-bottom: 1px solid #a9a9a9;
		margin-bottom: 15px;
	}
	.filter__block {
		.search {
			display: none;
		}
	}
	.filter__btn, .filter__block__btn {
		display: none;
	}
	ul {
		@include ul-default;
		li {
			padding-left: 47px;
			.checkbox {
				display: block;
				position: relative;
				padding: 13px 0;
				font-size: 14px;
				color: #58595b;
				line-height: 1;
				border-bottom: 1px solid #a9a9a9;
				cursor: pointer;
				margin: 0;
				transition: 300ms;
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					background: url(../img/check-symbol.svg) no-repeat center center;
					background-size: contain;
					position: absolute;
					left: -30px;
					top: 10px;
					opacity: 0;
					transition: 300ms;
				}
				&:hover {
					&:after {
						opacity: 0.6;
					}
				}
			}
		}
	}
	.extra {
		.filter__title {
			span {
				display: table;
				cursor: pointer;
				user-select: none;
				position: relative;
				padding-right: 25px;
				&:after {
					content: '\f107';
					font-family: 'fontAwesome';
					font-size: 22px;
					color: #7ccdc9;
					position: absolute;
					right: 0px;
					top: -1px;
					line-height: 13px;
				}
			}
			&.open {
				span:after {
					transform: rotate(-180deg);
				}
			}
		}
		.btn {
			margin: 20px auto 0;
		}
	}
	.filter__group {
		display: none;
		ul li {
			display: block;
			padding-left: 0;
			padding: 20px 0;
			.select {
				@include inline-block;
			}
			label {
				font-family: $font1;
				font-size: 15px;
				color: #6d6e71;
				margin: 5px 5px 0px 5px;
			}
		}
	}
	input[type="checkbox"] {
		display: none;
	}
	input[type="checkbox"]:checked + label {
		font-family: $font5;
		&:after {
			opacity: 1;
		}
	}

	.select {
		min-width: 80px;
		position: relative;
		box-sizing: border-box;
		select {
			width: 100%;
			background-color: #f7f7f7;
		    border: 1px solid #ececec;
		    box-shadow: inset 0 0 3px rgba(0,0,0,.1);
		    padding: 6px 10px 5px 30px;
		    font-family: $font4;
		    font-size: 14px;
		    text-align: right;
		    text-align-last: right;
		    vertical-align: middle;
		    appearance: none;
		    border: 0px;
	        direction: rtl;
	        outline: none;
		}
		option {
			font-family: $font3;
			padding: 2px 10px;
			font-size: 14px;
		}
		&:after {
			content: '\f107';
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #6d6e71;
			position: absolute;
			left: 8px;
			top: 4px;
		}
	}
	.filter--down {
		margin-top: 10px;
		.number--of--models {
			margin-top: 8px;
			@include inline-block;
		}
		.sort {
			margin-top: 8px;
			margin-left: 150px;
			@include inline-block;
			.select {
				margin: -6px 0 0 10px;
				@include inline-block;
				&:after {
					left: auto;
					right: 8px;
					top: 6px;
				}
				select {
					padding: 6px 30px 5px 10px;
					box-shadow: none;
					background-color: transparent;
					text-align: left;
					text-align-last: left;
					direction: ltr;
				}
			}
		}
	}
	.search {
		display: block;
		width: 400px;
		float: right;
		box-sizing: border-box;
		border: 1px solid #d7d7d7;
		position: relative;
		padding-right: 34px;
		z-index: 1;
		@include inline-block;
		input[type="text"] {
			width: 100%;
			height: 34px;
			vertical-align: middle;
			box-sizing: border-box;
			border: none;
			outline: none;
			appearance: auto;
		}
		input[type="submit"] {
			width: 34px;
			position: absolute;
			top: 0;				
			bottom: 0;
			right: 0;
			border: none;
			background: url(../img/search.svg) no-repeat center center;
			background-size: 20px;
			padding: 0;
		}
	}
	@media screen and (max-width: 1199px) {
		.row {
			margin: 25px 0;
		}
		.filter--down {
			.search {
				width: 350px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.row {
			margin: 0;
		}
		ul li {
			padding-left: 30px;
			.checkbox:after {
				left: -25px;
			}
		}
		.filter__btn, .filter__block__btn {
			display: block;
			font-size: 16px;
			padding: 10px 30px 10px 0;
			border-bottom: 1px solid #a9a9a9;
			margin: 0 15px;
			text-decoration: none;
			position: relative;
			&:after {
			    content: '\f107';
			    font-family: fontAwesome;
			    font-size: 20px;
			    color: #6d6e71;
			    position: absolute;
			    right: 8px;
			    top: 11px;
			}
		}
		.filter__block__btn:after {
			transform: rotate(-180deg);
		}
		.filter__block {
			display: none;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: #fff;
			overflow: auto;
			z-index: 9;
			.search {
				display: block;
				width: 80%;
				float: none;
				margin: 25px auto;
			}
		}
		.col-sm-4 {
			width: 100%;
			ul {
				display: none;
				padding-bottom: 15px;
			}
		}
		.filter__title {
			position: relative;
			padding-right: 30px;
			margin-bottom: 0;
			&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				right: 5px;
				top: 50%;
				width: 15px;
				height: 2px;
				background-color: #7accc8;
				margin-top: -1px;
			}
			&:after {
				transform: rotate(90deg);
			}
			span {
				&:after {
					display: none;
				}
			}
			&.open:after {
				display: none;
			}
		}
		.filter--down {
			.sort {
				float: right;
				margin-left: 0;
			}
			.search {
				display: none;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.filter__btn {
			margin: 0;
		}
		.filter--down .col-lg-12 {
			padding: 0;
		}
	}
	@media screen and (max-width: 480px) {
		.filter__block {
			.search {
				width: calc(100% - 30px);
			}
		}
		.filter--down {
			.sort span {
				display: none;
			}
		}
	}
}

.table-list {
	tr {
		td {
			&:first-of-type {
				width: 50%;
			}
			img {
				max-width: 100%;
			}
		}
	}
	@media screen and (max-width: 767px) {
		tr {
			td {
				display: block;
				width: 100%!important;
				padding: 10px 0!important;
			}
		}
	}
}

.catalog {
	.last {
		margin-top: 100px;
		.product--cat__col {
			margin-top: 0;
			.btn {
				margin: 25px 0;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.last {
			margin-top: 25px;
			.product--cat__col .btn {
				margin: 15px auto;
			}
		}
	}
}
.product--cat__col {
	display: block;
	margin: 25px 0px;
	text-decoration: none!important;
	color: #58595b!important;
	.product--cat__col__img {
		display: block;
		overflow: hidden;
		margin-bottom: 15px;
		img {
			display: block;
			width: 100%;
		}
	}
	.product--cat__col__about {
		display: block;
		font-family: $font3;
		font-size: 12px;
		text-transform: uppercase;
		color: #58595b;
	}
	.product--cat__col__info {
		.product--cat__col__info__col {
			width: 45%;
			padding-right: 15px;
			box-sizing: border-box;
			@include inline-block;
			span {
				display: block;
				font-family: $font3;
				font-size: 12px;
			}
			.area {
				font-family: $font5;
				font-size: 18px;
				margin-top: 5px;
			}
		}
	}
	h3 {
		padding-bottom: 15px;
		margin: 15px 0;
		border-bottom: 1px solid #cccccc;
	}
	&:hover {
		color: #58595b;
		span {
			color: #58595b;
		}
	}
}

.product--cat {
	.product--cat__img--block {
		position: relative;
		> img {
			display: block;
			width: 100%;
		}
		.product--cat__img--block__info {
			max-width: 650px;
			position: absolute;
			left: 0;
			top: 50%;
			left: 85px;
			z-index: 1;
			color: #fff;
			margin-top: -70px;
			.product--cat__img--block__info__title {
				display: table;
				font-size: 17px;
				text-transform: uppercase;
				padding: 0px 100px 20px 0;
				border-bottom: 2px solid #fff;
				margin-bottom: 20px;
			}
		}
		&.block--title {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0,0,0,0.4);
			}

		}
	}
	.product--cat__col {
		margin: 0;
	}
	table tr {
		td {
			padding: 75px 75px;
			img {
				max-width: 100%;
			}
			// &:nth-of-type(1) {
			// 	width: 25%;
			// 	text-align: center;
			// }
		}
	}
	.product--cat__block {
		position: relative;
		.product--cat__block__slider {
			padding: 75px 0px;
			margin-bottom: 0!important;
			.slick-list  {
				padding: 0 25%!important;
			}
			.slick-slide {
				outline: none;
				text-align: center;
				position: relative;
				text-decoration: none;
				margin: 0 25px;
				overflow: hidden;
				&:hover {
					color: #58595b;
				}
				&:after {
					content: '';
					display: block;
					width: 50px;
					height: 50px;
					border-radius: 100%;
					background-color: #7f7f7f;
					background-image: url(../img/extent-option-arrows.svg);
					background-size: 20px;
					background-position: center center;
					background-repeat: no-repeat;
					position: absolute;
					top: 10px;
					right: 10px;
				}
				img {
					max-width: 100%;
					margin: 0 auto;
				}
				span {
					display: block;
					margin: 15px 0;
				}
			}
			.slick-dots {
				bottom: 10px;
				li {
					height: auto;
					width: auto;
					border: 2px solid transparent;
					border-radius: 100%;
					padding: 2px;
					margin: 5px;
					transition: 300ms;
					button {
						width: 10px;
						height: 10px;
						padding: 0;
						border-radius: 100%;
						background-color: #7f7f7f;
						transition: 300ms;	
						&:before {
							display: none;
						}
					}
					&:hover, &.slick-active {
						border: 2px solid #7f7f7f;
						button {
							background-color: #fff;
						}
					}
				}
			}
		}
	}
	.btn {
		margin: 25px auto 50px;
	}
	@media screen and (max-width: 1199px) {
		table tr td:nth-of-type(1) {
			width: 35%;
		}
	}
	@media screen and (max-width: 991px) {
		table tr td {
			padding: 35px;
		}
		.product--cat__img--block {
			.product--cat__img--block__info {
				width: auto;
				left: 25px;
				right: 25px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.product--cat__block .product--cat__block__slider {
			padding: 0 10px 50px 10px;
			box-sizing: border-box;
			overflow: hidden;
			.slick-list {
				padding: 0 0!important;
			}
			.slick-dots {
				right: 10px;
				left: 10px;
				width: auto;
			}
			img {
				max-width: 100%!important;
			}
		}
	}
	@media screen and (max-width: 580px) {
		table tr td {
			padding: 25px 15px;
		}
		.product--cat__img--block { 
			.product--cat__img--block__info {
				top: auto;
				left: 10px;
				right: 10px;
				bottom: 15px;
				margin: 0;
				.product--cat__img--block__info__title {
					font-size: 15px;
					padding: 0 0 10px 0;
					margin-bottom: 10px;
				}
			}
		}
	}
	@media screen and (max-width: 420px) {
		table tr td {
			display: block;
			width: 100%;
			padding: 10px 15px;
			&:nth-of-type(1) {
				width: 100%;
			}
			img {
				display: block;
				margin: auto;
			}
		}
		.product--cat__img--block {
			overflow: hidden;
			> img {
				height: 300px;
				width: auto;
			}
		}
	}
}

.product--cat--indent {
	.product--cat__col, .product--cat__img--block {
		margin: 25px 0 50px 0;
	}
	.product--cat__img--block {
		margin-bottom: 50px;
	}
	@media screen and (max-width: 767px) {
		padding: 0 30px;
		.product--cat__col, .product--cat__img--block {
			margin: 15px 0 25px 0;
		}
	}
}

.product--cat__block__miniatur--slider {
	background-color: #f4f3ef;
	text-align: center;
	padding: 60px 0 120px;
	margin-bottom: 0!important;
	.slick-list {
		padding: 0 25%!important;
	}
	.slick-slide {
		outline: none;
		padding: 60px 0px;	
		margin: 0 15px;
		&:hover {
			color: #58595b;
		}
		span {
			display: block;
			font-family: $font4;
			font-size: 15px;
			text-decoration: underline;
			margin: 45px 0 15px;
		}
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
			transition: 400ms;
		}
		&.slick-active img {
			transform: scale(1.2);
			transform-origin: center center;
		}
	}
	.custom--dots {	
		position: absolute;
		bottom: 25px;
		left: 0;
		right: 0;
		text-align: center;
		@include ul-default;
		li {
			display: none;
			font-family: $font4;
			font-size: 24px;
			&.slick-active {
				display: block;
			}
		}
	}
	.slick-arrow {
		width: 35px;
		height: 35px;
		border-radius: 100%;
		border: 2px solid #6f7072;
		top: auto;
		bottom: 25px;
		transform-origin: center center;
		z-index: 1;
		transition: 300ms;
		&:before {
			display: none;
		}
		&:after {
			content: '\f104';
			font-family: 'fontAwesome';
			font-size: 30px;
			color: #58595b;
			line-height: 23px;
			margin: -2px 0 0 -2px;		
		}
		&.slick-prev {
			left: 44%;
			transform: rotate(0);
		}
		&.slick-next {
			right: 44%;
			transform: rotate(180deg);
		}
		&:hover {
			opacity: 0.7;
		}
	}
	@media screen and (max-width: 1199px) {
		.slick-slide {
			margin: 0 35px;
		}
		.slick-arrow {
			&.slick-prev {
				left: 40%;
			}
			&.slick-next {
				right: 40%;
			}
		}

	}
	@media screen and (max-width: 768px) {
		padding-top: 25px;
		.slick-slide {
			padding: 0;
			margin: 0 15px;
			&.slick-active {
				img {
					transform: scale(1);
				}
			}
		}
		.slick-arrow {
			&.slick-prev {
				left: 35%;
			}
			&.slick-next {
				right: 35%;
			}
		}

	}
	@media screen and (max-width: 480px) {
		.slick-arrow {
			&.slick-prev {
				left: 30%;
			}
			&.slick-next {
				right: 30%;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.slick-list {
			padding: 0!important;
		}
		.slick-arrow {
			&.slick-prev {
				left: 25%;
			}
			&.slick-next {
				right: 25%;
			}
		}
	}
}
.product--cat__block__miniatur--slider--title {
	display: block;
	font-size: 15px;
	text-decoration: underline;
	text-align: center;
	position: absolute;
	bottom: 70px;
	left: 0;
	right: 0;
	&:hover {
		text-decoration: none;
	}
}

/* page catalog */

/* page contacts */
.contacts {
	padding-top: 200px;
	h1 {
		display: block;
		font-family: $font2;
		font-size: 25px;
		color: #7accc8;
		text-transform: inherit;
		text-align: left;
		padding-bottom: 25px;
		border-bottom: 2px solid #a9a9a9;
	}
	h3 {
		margin-top: 0;
	}
	.contacts--filter {
		.col-sm-6 {
			p {
				font-family: $font4;
			}
			input[type="text"],
			input[type="email"],
			textarea {
				display: block;
				height: 43px;
				width: 100%;
				border: 1px solid #d7d7d7;
				box-sizing: border-box;
				padding: 8px 30px 8px 13px;
				font-family: $font2;
				font-size: 17px;
				appearance: none;
				margin-bottom: 25px;
				box-sizing: border-box;
			}
			input[type="email"] {
				margin-bottom: 0;
			}
			textarea {
				height: 179px;
			}
			.g-recaptcha {
				@include inline-block;
			}
			.btn {
				float: right;
				padding: 12px 45px 13px!important;
				margin-top: 15px;
				@include inline-block;
			}
		}
	}
	.send {
		font-family: $font4;
	}
	.location {
		display: table;
		font-family: $font4;
		padding: 5px 0px 5px 30px;
		position: relative;
		background: url(../img/location.png) no-repeat top left;
		text-decoration: none;
		margin: 15px 0;
	}
	.contacts__col {
		ul {
			@include ul-default;
			li {
				margin-bottom: 25px;		
				.title--city {
					display: block;
					font-size: 18px;
					color: #7accc8;
					margin-bottom: 10px;
					cursor: pointer;
				}
				a {
					display: block;
					text-decoration: none;
				}
				.send {
					margin: 10px 0;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.map {
			> ymaps {
				height: 300px!important;
			}
		}
	}
	@media screen and (max-width: 767px) {
		padding-top: 75px;
		.contacts--filter {
			.col-sm-6 {
				input[type="text"],
				input[type="email"],
				textarea {
					margin-bottom: 15px;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		.g-recaptcha {
			display: block;
			float: right;
			> div {
				transform: scale(0.9);
				transform-origin: 100% 0;
				float: right;
			}
		}
	}
}
/* page contacts */

/* page pagination */
.pagination {
	font-size: 0;
	margin: 25px 0;
	ul {
		@include ul-default;
		li {
			margin: 0 10px 5px 0;
			@include inline-block;
			a {
				display: block;
				height: 35px;
				width: 25px;
				font-size: 14px;
				text-decoration: none;
				text-align: center;
				border: 1px solid #7accc8;
				padding-top: 6px;
				box-sizing: border-box;
				&:hover {
					background-color: #7accc8;
					color: #fff;
				}
			}
			&.active a {
				background-color: #7accc8;
				color: #fff;
			}
			&.prev--all,
			&.prev,
			&.next--all,
			&.next {
				a {
					width: 15px;
					border: none;
					position: relative;
					font-family: 'fontAwesome';
					font-size: 22px;
					color: #7accc8;
					&:after {
						text-align: center;
					}
					&:hover {
						background-color: transparent;
					}
				}
			}
			&.prev--all a:after {
				content: '\f100';
			}
			&.prev a:after {
				content: '\f104';
			}
			&.next--all a:after {
				content: '\f101';
			}
			&.next a:after {
				content: '\f105';
			}
		}
	}
}
/* page pagination */

/* modal */
.modal {
	.modal-content {
		max-width: 400px;
		border-radius: 0px;
		margin: 0 auto;
		h4 {
			margin: 0;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		form {
			display: block;
			padding-bottom: 130px;
			margin-top: 10px;
			input[type="text"],
			input[type="email"],
			textarea {
				display: block;
				width: 100%;
				border: 1px solid #d7d7d7;
				padding: 8px 30px 8px 13px;
				margin-bottom: 15px;
			}
			.g-recaptcha {
				float: right;
			}
			.btn {
				float: right;
				margin-top: 10px;
			}
		}
	}
	&#contacts--form {
		.modal-content {
			h4 {
				text-align: center;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.modal-content {
			form .g-recaptcha {
				transform: scale(0.9);
				transform-origin: 100% center;
				> div {
					float: right;
				}
			}
		}
	}
}
/* modal */

.btn--up {
	display: block;
	font-family: $font4;
	font-size: 18px;
	padding: 5px 15px;
	border-radius: 2px;
	background: #163f6e;
	position: fixed;
	bottom: 30px;
	right: 25px;
	color: #fff;
	opacity: 0;
	text-decoration: none!important;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
	z-index: 1;
	&:focus {
		color: #fff;
	}
	&:hover {
		text-decoration: none;
		color: #fff;
		opacity: 0.7!important;
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

/* page content */
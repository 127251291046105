/* footer */
footer {
	background-color: #f4f3ef;
	color: #272727;
	font-size: 14px;
	padding-top: 100px;
	h6 {
		color: #272727;
		margin: 0 0 25px 0;
	}
	ul {
		@include ul-default;
		li.active a {
			text-decoration: underline;
		}
	}
	a {
		color: #272727;
	}
	.footer--contacts {
		.col-sm-6 {
			margin-bottom: 25px;
		}
		.footer--contacts__phones {
			ul li {
				position: relative;
				@include inline-block;
				&:after {
					content: '/';
					font-size: 14px;
					margin: 3px 0px 0px 4px;
					@include inline-block;
				}
				&:last-of-type:after {
					display: none;
				}
			}
		}
	}
	.footer--socials {
		ul li {
			margin-right: 20px;
			@include inline-block;
			a {
				text-transform: uppercase;
			}
		}
	}
	.footer--down {
		padding: 50px 0px;
		p {
			margin: 0;
		}
		.artmedia {
			float: right;
			margin-top: -10px;
			opacity: 0.7;
		}
	}
	@media screen and (max-width: 1199px) {
		padding-top: 50px;
		.footer--down {
			padding: 25px 0;
		}
	}
	@media screen and (max-width: 768px) {
		text-align: center;
		padding-top: 25px;
		h6 {
			margin-bottom: 15px;
		}
		.footer--address {
			a {
				display: block;
				max-width: 75px;
				margin: 0 auto 25px;
				img {
					width: 100%;
				}
			}
		}
		.footer--down {
			.artmedia {
				display: table;
				float: none;
				margin: 15px auto 0;
			}
		}
	}
}
/* footer */
/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

.animate--show {
	opacity: 0;
	animation-name: bannerText;
	animation-duration: 2s;
	animation-direction: alternate;
	animation-timing-function: ease-out; 
	animation-fill-mode: forwards; 
	animation-delay: 1.5s; 
}

/* main banner */
.banner {
	.banner__img {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: -1;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.3);
		}
		> img {
			display: block;
			width: 100%;
		}
	}

	.banner__block {
		max-width: 100%;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		top: -65vh;
		padding: 0 25px;
		margin: 0 auto;
		z-index: 1;
		.banner__block__logo {
			display: table;
			margin: 0 auto 25px;
			opacity: 0;
			animation-name: bannerText;
			animation-duration: 3s;
			animation-direction: alternate;
			animation-timing-function: ease-out; 
			animation-fill-mode: forwards; 
			animation-delay: 1.5s; 
		}
		.banner__block__title {
			display: block;
			font-family: $font5;
			font-size: 48px;
			color: #fff;
			line-height: 1.3;
			opacity: 0;
			animation-name: bannerText;
			animation-duration: 3s; 
			animation-direction: alternate; 
			animation-timing-function: ease-out; 
			animation-fill-mode: forwards;
			animation-delay: 2.5s;
		}
		.banner__block__slogan {
			display: block;
			font-family: $font3;
			font-size: 24px;
			text-align: center;
			color: #fff;
			margin-top: 30px;	
			opacity: 0;	
			animation-name: bannerText;
			animation-duration: 3s; 
			animation-direction: alternate; 
			animation-timing-function: ease-out; 
			animation-fill-mode: forwards; 
			animation-delay: 3.5s; 
		}
	}
	@media screen and (max-width: 1440px) {
		position: relative;
		.banner__block {
			.banner__block__title {
				font-size: 38px;
			}
			.banner__block__slogan {
				margin-top: 15px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.banner__img {
			position: relative;
			z-index: 0;
		}
		.banner__block {
			top: auto;
			bottom: 25%;
			.banner__block__logo {
				width: 60px;
				margin: 10px auto;
				img {
					width: 100%;
				}
			}
			.banner__block__title { 
				font-size: 28px;
			}
			.banner__block__slogan {
				font-size: 22px;
				margin-top: 5px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.banner__block {
			bottom: 20%;
			.banner__block__title {
				font-size: 24px;
			}
			.banner__block__slogan {
				font-size: 20px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		overflow: hidden;
		.banner__img > img {
			width: auto;
			height: 300px;
		}
		.banner__block {
			padding: 0 10px;
			bottom: 15%;
			.banner__block__logo {
				width: 45px;
			}
			.banner__block__title {
				font-size: 20px;
			}
			.banner__block__slogan {
				font-size: 16px;
			}
		}
	}
}
@keyframes bannerText {
    from {opacity: 0}
    to {opacity: 1;}
}
/* main banner */

/* main company */
.company {
	.container {
		padding-bottom: 0px;
	}
	h3 {
		margin-top: 0;
	}
	p {
		margin-bottom: 0;
	}
	@media screen and (max-width: 767px) {
		.col-sm-6 {
			padding: 0 15px;
		}
	}
}
/* main company */

/* main price */
.price {
	.container {
		padding: 35px 30px;
	}
	.price__col {
		display: block;
		margin: 25px 0;
		color: #58595b;
		.price__col__img {
			margin-bottom: 40px;
			img {
				display: block;
				width: 100%;
			}
		}
		h5 {
			margin: 0 0 20px 0;
			color: #58595b;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			padding: 15px;
		}
		.price__col {
			.price__col__img {
				margin-bottom: 20px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.price__col {
			h5 {
				margin-bottom: 10px;
			}
		}
	}
}
/* main price */

/* main project */
.project {
	background-color: #f4f3ef;
	.container {
		padding: 0 30px 50px 30px;
	}
	.row:last-of-type {
		a:after {
			display: none;
		}
	}
	a {
		display: table;
		width: 100%;
		position: relative;
		padding: 50px 0;
		&:after {
			content: '';
			height: 1px;
			position: absolute;
			left: 30px;
			right: 30px;
			bottom: 0;
			background-color: #c3c2bf;
		}
		.col-sm-6 {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
			float: none;
		}
	}
	.project__img {
		position: relative;
		> img {
			display: block;
			width: 100%;
		}
		.sticker {
			display: block;
			position: absolute;
			top: 25px;
			right: 25px;
		}
	}
	.project__info {
		.project__info__logo {
			display: table;
			margin: 15px auto;
		}
		.project__info__title {
			display: block;
			font-family: $font4;
			font-size: 15px;
			text-align: center;
			margin: 0 0 25px 0;
		}
		p .more {
			margin-top: 2px;
			@include inline-block;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			padding: 15px;
		}
		a {
			padding: 25px 0;
		}
		.project__info .project__info__title {
			margin-bottom: 15px;
		}
	}
	@media screen and (max-width: 767px) {
		a .col-sm-6 {
			display: block;
			width: 100%;
		}
		.project__img .sticker {
			width: 80px;
			height: 80px;
			top: 10px;
			right: 10px;
			img {
				width: 100%;
			}
		}
	}
}
/* main project */

/* main about */
.about {
	min-height: 700px;
	background: url(../img/img-10.jpg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: 45%;
	.more {
		margin-top: 25px;
	}
	.col-md-5 {
		padding-top: 185px;
	}
	h3 {
		margin: 0 0 25px 0;
	}
	@media screen and (max-width: 991px) {
		.container {
			padding: 25px 15px;
		}
	}
	@media screen and (max-width: 767px) {
		min-height: 1px;
		background: transparent;
		.col-md-5 {
			padding-top: 0;
		}
	}
}
/* main about */

/* main log */
.log {
	background-color: #f4f3ef;
	.log__list {
		width: 190px;
		text-align: center;
		margin-top: 40px;
		@include inline-block;
		ul {
			@include ul-default;
			li {
				margin: 25px 0;
			}
		}
	}
	.log__img {
		width: calc(100% - 195px);
		overflow: hidden;
		@include inline-block;
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}
	}
	h3 {
		margin: 0 0 25px 0;
	}
	.more {
		margin-top: 25px;
	}
	.col-md-6 {
		margin-top: 125px;
	}
	@media screen and (max-width: 1610px) {
		.log__list {
			margin-top: 0;
		}
		.col-md-6 {
			margin-top: 50px;
		}
	}
	@media screen and (max-width: 1199px) {
		.col-md-6 {
			margin-top: 0;
		}
	}
	@media screen and (max-width: 991px) {
		.log__img {
			display: none;
		}
		.log__list {
			width: 100%;
			text-align: center;
			ul li {
				width: 32%;
				@include inline-block;
			}
		}
		.col-sm-6 {
			width: 100%;
		}
	}
	@media screen and (max-width: 560px) {
		.log__list {
			ul li {
				display: block;
				width: 100%;
			}
		}
	}
}
/* main log */


/* main content */